// create a react page that embeds a facebook page
//
import React, {useEffect, useState} from 'react';

import NavBar from "../components/NavBar";
import BottomBar from "../components/BottomBar";
import {useLocation} from "react-router-dom";
import BlogPosts from "../components/BlogPosts";
import {collection, getDocs, orderBy, query} from "@firebase/firestore";
import {db} from "../firebase";
import ProgramCardModal from "../components/Programs/ProgramCardModal";
import {ImageDir} from "../assets/imageDir";
import BlogPostModal from "../components/BlogPostModal";


function Announcements() {
    // firebase get all the posts insdie the collectino updatesc
   const [allPosts, setAllPosts] = useState([]);
   const [loading, setLoading] = useState(true);
   const [selectedPost, setSelectedPost] = useState(
       {
              'name': '',
              'header_image': '',
              'content': '',
              'created_on': ''
       }
   );
   const [modalShow, setModalShow] = useState(false);
   const getPosts = async () => {
       const posts = []
       const q = query(collection(db, "updates"), orderBy("created_on", "desc"));

       const snapshot = await getDocs(q);
        snapshot.forEach((doc) => {
            if (doc.exists) {
                console.log(doc.id, " => ", doc.data());
                posts.push(doc.data());
                setAllPosts(posts);

            }   else {
                console.log("No such document!");


            }

        });

   }
    useEffect(() => {
          getPosts().then(r => console.log(r));

     }, []);
    const closeModal = () => {
        setSelectedPost(
            {
                'name': '',
                'header_image': '',
                'content': '',
                'created_on': ''
            }
        );
    };


    // use effect if selected post is not empty
//    get the post
    useEffect(() => {
        if (selectedPost['name'] !== '') {
        //     console post the post
            console.log(selectedPost.name);
        setModalShow(true)
        }
    }, [selectedPost]);
    return (
        <div className="bg-white min-h-screen p-8 md:pt-16 sm:pt-4 text-center">

            {/*create a grid for blog posts that is responsive*/}
            <div className={"text-black text-4xl font-extrabold mb-8"}>{'Announcements'}</div>

            <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4"}>

                {allPosts.length === 0 && loading &&
                //     spinner
                <div className="flex justify-center items-center">
                    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
                </div>


                }

            {

                allPosts.map((post, index) => {
                    return <BlogPosts key={index}
                                      name={post['name']}
                                      headerImage={post['header_image']}
                                      content={post['content']}
                                      createdOn={post['created_on']}
                                      onClick={() => setSelectedPost({
                                            'name': post['name'],
                                            'header_image': post['header_image'],
                                            'content': post['content'],
                                            'created_on': post['created_on']
                                      })}

                    />
                })

            }


                {selectedPost.name !== '' && (
                //     create blog post modal
                    <BlogPostModal
                        name={selectedPost.name}
                        headerImage={selectedPost.header_image}
                        content={selectedPost.content}
                        createdOn={selectedPost.created_on}
                        onClose={closeModal}/>


                )}

            </div>



        </div>
    );
}

export default Announcements;