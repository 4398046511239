import React, {useEffect, useState} from 'react';
import {getDownloadURL, ref} from "firebase/storage";
import {storage} from "../firebase";

const BlogPost = ({ name , headerImage, content, createdOn, onClick }) => {
    const [fullScreen, setFullScreen] = useState(false);
    const [image, setImage] = useState('');
    console.log(createdOn.seconds);
    const formattedDate = new Date(createdOn.seconds * 1000).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });

    const getImage = async () => {

        if (!headerImage) {
            return;
        }
        getDownloadURL(ref(storage,headerImage))
            .then((url) => {
                // Insert url into an <img> tag to "download"
                //         modify the header_image to the url
                //    add another field to the doc.data() object

                console.log("get",headerImage,url);
                setImage(url);

            })
            .catch((error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/object-not-found':
                        // File doesn't exist
                        break;
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        break;

                    // ...

                    case 'storage/unknown':
                        // Unknown error occurred, inspect the server response
                        break;
                }



            });

    }
    useEffect(() => {
        getImage().then(r => console.log(r));
    }, []);

    return (
        <>
        {/*    add a whole screen reader*/}
            {
                fullScreen && (
                    <div className="fixed inset-0 bg-black bg-opacity-70 z-50">
                        <div className="absolute inset-0 flex items-center justify-center">
                            <img src={image} alt={name} className="w-1/2 rounded-md shadow-lg"
                                 style={{height: '50rem', objectFit: 'cover'}}
                            />
                        </div>
                        <button className="absolute top-4 right-4 text-white text-2xl" onClick={() => setFullScreen(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                      d="M6 18L18 6M6 6l12 12"/>
                            </svg>
                        </button>
                    </div>
                )
            }
        <div className="w-full bg-gray-100 p-4 rounded-xl  hover:shadow-lg
        transition duration-300 ease-in-out cursor-pointer
        border-2 border-gray-300 hover:border-gray-300

        flex    flex-col justify-between

        ">
            <div className="mb-8">
                <img src={image} alt={name} className="w-full rounded-md shadow-lg"
                        style={{height: '24rem', objectFit: 'cover'}}
                     onClick={() => setFullScreen(true)}
                />
            </div>
            <div className="mb-8">
                <h1 className="text-3xl font-bold mb-4">{name}</h1>
                <p className="text-gray-600">{formattedDate}</p>
            </div>
            <div className="prose max-w-xl self-center">
                {content.map((section, index) => (
                    <React.Fragment key={index}>
                        {section.type === 'text' && <p className="mb-4">{section.value.substring(0,100)}</p>}
                    </React.Fragment>
                ))}
            </div>
        {/*    have a More button*/}
            <button className="bg-gray-900 text-white text-bold px-4 py-2 rounded-lg "
            onClick={onClick}
            >Read More</button>

        {/*    end line*/}
        {/*    <div className={"border-b-2 border-gray-700 my-8"}></div>*/}
        </div>
            </>
    );
};

export default BlogPost;
