import React, { useEffect, useState } from 'react';
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../firebase";

const BlogPostModal = ({  name, headerImage, content, createdOn, onClose }) => {
    const [fullScreen, setFullScreen] = useState(false);
    const [image, setImage] = useState('');
    const formattedDate = new Date(createdOn.seconds * 1000).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });

    const getImage = async () => {

        if (!headerImage) {
            return;
        }
        getDownloadURL(ref(storage,headerImage))
            .then((url) => {
                // Insert url into an <img> tag to "download"
                //         modify the header_image to the url
                //    add another field to the doc.data() object

                console.log("get",headerImage,url);
                setImage(url);

            })
            .catch((error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/object-not-found':
                        // File doesn't exist
                        break;
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        break;

                    // ...

                    case 'storage/unknown':
                        // Unknown error occurred, inspect the server response
                        break;
                }



            });

    }
    useEffect(() => {
        getImage().then(r => console.log(r));
    }, []);


    return (
        <>
            {/* Full screen mode */}
            {fullScreen && (
                <div className="fixed inset-0 bg-black bg-opacity-70 z-50">
                    <div className="absolute inset-0 flex items-center justify-center">
                        <img
                            src={image}
                            alt={name}
                            className="w-1/2 rounded-md shadow-lg"
                            style={{ height: '50rem', objectFit: 'cover' }}
                        />
                    </div>
                    <button className="absolute top-4 right-4 text-white text-2xl" onClick={() => setFullScreen(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
            )}

            {/* Modal content */}
            <div className="fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-75 flex items-start justify-center">
                <div className="relative bg-white p-8 max-w-lg mx-auto">
                    <button onClick={onClose} className="absolute top-0 right-0 p-2 m-2 text-gray-700 hover:text-gray-900 focus:outline-none">
                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                    <h2 className="text-2xl font-bold mb-4">{name}</h2>
                    {image && <img src={image} alt={name} className="mb-4" />}
                    {content.map((section, index) => (
                        <React.Fragment key={index}>
                            {section.type === 'text' && <p className="mb-4 text-left">{section.value}</p>}
                        </React.Fragment>
                    ))}
                    <p className="text-sm text-gray-500 mt-4">Created on: {formattedDate}</p>
                </div>
            </div>
        </>
    );
};

export default BlogPostModal;
