// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAnalytics } from "firebase/analytics";
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDF0a7EFvfAjlJ5P8mrWQAFonZzIt3onrY",
    authDomain: "neins-2144f-eb60c.firebaseapp.com",
    projectId: "neins-2144f",
    storageBucket: "neins-2144f.appspot.com",
    messagingSenderId: "177130582817",
    appId: "1:177130582817:web:5eb897ff17237364194460",
    measurementId: "G-1LBQWVYBG9"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const storage = getStorage();
