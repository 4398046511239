// export stringse
export const Strings = {
    UniversityName: 'NEINS',
    Slogan: 'Where Compassion Meets Excellence',
    FullName: 'North East Institute of Nursing Sciences',
    Description: 'Shaping future healthcare professionals through exceptional nursing education. Join us for a transformative journey towards a rewarding nursing career.',
    Address: 'Bypass Road, near Sai Baba Temple, Amtali, Agartala, Tripura, 799130, India.',
    Email: 'admissions@neins.ind.in',
    Phone: '+917005798095/+918837236437 ( Both Call & WhatsApp available )',


    whyChooseUs: 'Please send me write up for this.',
    wordsfromChairman: 'Nursing is a profession in which the practitioner applies knowledge and skill in the provision of preventive, curative, promotive, and rehabilitative health services to individuals, families, and communities. Nursing is one of the most exciting and challenging professions in healthcare! I encourage you to embark on an educational path that will prepare you for a career in Nursing.',
    wordsfromPrincipal:'The nursing profession is noble, and with advancements in healthcare, nurses must stay updated with scientific knowledge. North East Institute of Nursing Science is committed to providing high-quality education, ensuring the holistic development of students to serve society and excel in global settings. The college aims to build a strong foundation in nursing, preparing students for roles as caregivers, administrators, educators, and researchers. The management focuses on improving nursing education standards, with excellent infrastructure and a team of qualified, experienced teachers. Students enjoy a student-friendly atmosphere and are encouraged to participate in sports and cultural activities. The college is proud of its alumni working in hospitals across the country and offers job opportunities abroad through a partnership with the National Skill Development Corporation (NSDC). Future plans include launching Postgraduate programs in Nursing and becoming a Centre of Excellence. I warmly invite you to be a proud nursing student of North East Institute of Nursing Science. Prof. Sarojlata Akham',
    wordsfromDean: 'Please send me write up for this.',

};
